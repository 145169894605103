import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 35 44"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "m4.338 20.274 12.085 7.63 12.003-7.585M16.424 41.431V27.889",
      style: {"fill":"none","fill-rule":"nonzero","stroke":"currentColor","stroke-width":"3px"}
    }, null, -1),
    _createElementVNode("path", {
      d: "m13.59 12.869-7.308 4.434c-1.657 1.001-3.011 3.509-3.011 5.569v8.436c0 2.061 1.354 4.569 3.011 5.569l7.308 4.435c1.56.94 4.12.94 5.68 0l7.308-4.435c1.657-1 3.012-3.508 3.012-5.569v-8.436c0-2.06-1.355-4.568-3.012-5.569l-7.308-4.434c-1.574-.956-4.12-.956-5.68 0Z",
      style: {"fill":"none","fill-rule":"nonzero","stroke":"currentColor","stroke-width":"3px"}
    }, null, -1),
    _createElementVNode("path", {
      d: "m16.831 11.604-.005 10.293M19.501 24.222l-2.837-1.58-3.59 2.192",
      style: {"fill":"none","fill-rule":"nonzero","stroke":"currentColor","stroke-width":"2px"}
    }, null, -1),
    _createElementVNode("path", {
      d: "M26.002 1.667A8.336 8.336 0 0 0 17.669 10c0 4.6 3.733 8.334 8.333 8.334S34.336 14.6 34.336 10s-3.734-8.333-8.334-8.333m4.167 9.166h-3.334v3.334h-1.666v-3.334h-3.334V9.167h3.334V5.833h1.666v3.334h3.334z",
      style: {"fill":"currentColor","fill-rule":"nonzero"}
    }, null, -1)
  ])))
}
export default { render: render }