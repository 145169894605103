import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 30 38"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M21.504 6.55v11.587a1.575 1.575 0 0 0 1.653 1.575h1.669a1.574 1.574 0 0 1 1.653 1.652v10.06a1.575 1.575 0 0 0 1.653 1.574 1.574 1.574 0 0 0 1.653-1.574V1.812A1.667 1.667 0 0 0 27.722.238c-3.573.835-6.218 3.857-6.218 6.313m-9.918 4.975a1.574 1.574 0 0 1-1.652-1.652v-8.28A1.574 1.574 0 0 0 8.359.017a1.653 1.653 0 0 0-1.747 1.574v8.28a1.574 1.574 0 0 1-1.653 1.653 1.575 1.575 0 0 1-1.574-1.652v-8.28A1.574 1.574 0 0 0 1.81.017 1.574 1.574 0 0 0 0 1.592v9.933a6.61 6.61 0 0 0 5.242 6.47 1.684 1.684 0 0 1 1.37 1.575v11.854a1.653 1.653 0 0 0 1.669 1.574 1.573 1.573 0 0 0 1.574-1.653V19.617a1.684 1.684 0 0 1 1.37-1.574 6.61 6.61 0 0 0 5.242-6.47v-9.98A1.574 1.574 0 0 0 14.892.017a1.575 1.575 0 0 0-1.653 1.574v8.28a1.574 1.574 0 0 1-1.653 1.653"
    }, null, -1)
  ])))
}
export default { render: render }